.menu-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.menu-enter-active {
  max-height: 200px;
}

.menu-exit {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.menu-exit-active {
  max-height: 0;
}
